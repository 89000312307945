import React, { useState, useEffect, useMemo, useContext } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import Detalhamento from './detalhamento';

import * as S from './styles';
import { AjuizamentoResponse, AcompanhamentoAjuizamentoItem, AjuizamentoDetalhes } from './types';
import api from '../../../_core/api';
import { handleErrors } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { useCache } from '../../../_core/cache';
import { requests } from '../../../requests';
import { Button, Checkbox, Input, Option, Select, SingleSelect } from '../../../_core/_components';
import { AuthContext } from '../../../_main/contexts/auth';
import { Action } from '../cadastro/tipos-acao';
import Main from './main';

const FASE_ATUAL: Option[] = [
  { value: 1, label: 'DOCUMENTAÇÃO' },
  { value: 2, label: 'GUIA DE PAGAMENTO' },
  { value: 3, label: 'PO' },
  { value: 4, label: 'CHAMADO PARA PAGAMENTO' },
  { value: 5, label: 'COMPROVANTES DE PAGAMENTO' },
  { value: 6, label: 'CHAMADO PARA AJUIZAMENTO' },
  { value: 7, label: 'AÇÃO AJUIZADA' },
];

export const AcompanharAjuizamento: React.FC = () => {
  const { shoppings: profileShoppings } = useContext(AuthContext);
  const { response: responseGruposCobranca, isLoading: isLoadingGruposCobranca } = useCache({
    key: 'getGruposCobranca',
    fn: requests.get('getGruposCobranca'),
  });
  const [tipoAcaoOptions, setTipoAcaoOptions] = useState<Option[]>([]);
  const [acoesPendentes, setAcoesPendentes] = useState(true);
  const [acoesAjuizadas, setAcoesAjuizadas] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [ajuizamentos, setAjuizamentos] = useState<AjuizamentoResponse | null>(null);
  const [ajuizamentoDetalhes, setAjuizamentoDetalhes] = useState<AjuizamentoDetalhes | undefined>(undefined);

  const [page, setPage] = React.useState(1);

  /* Filtros Selecionados */
  const [selectedShopping, setselectedShopping] = useState<Option[]>([]);
  const [billingGroups, setBillingGroups] = useState<Option[]>([]);
  const [billingGroupsList, setBillingGroupsList] = useState<Option[]>([]);
  const [tipoAcao, setTipoAcao] = useState<Option[]>([]);
  const [idsFases, setIdsFases] = useState<Option[]>([]);
  const [numeroChamado, setNumeroChamado] = useState<string>('');
  const [cpfCnpj, setCpfCnpj] = useState<string>('');
  const [nomeFantasia, setNomeFantasia] = useState<string>('');
  const [luc, setLuc] = useState<string>('');
  const [mesAnoCicloAjuizamento, setMesAnoCicloAjuizamento] = useState<Option>();
  const [visibleSection, setVisibleSection] = useState<'Main' | 'Details'>('Main');

  useEffect(() => {
    const getTipoAcaoData = async () => {
      const { data } = await api.get<Action[]>(`/setup/rules/tipo-acao`);
      setTipoAcaoOptions(
        data.map(e => ({
          value: e.idAjzTipoAcao,
          label: e.nomTipoAcao,
        })),
      );
    };
    getTipoAcaoData();
  }, []);

  const shoppingsOptions = useMemo(() => {
    if (profileShoppings) {
      const optioned = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      // setselectedShopping(optioned);
      return _.orderBy(optioned, ['label'], ['asc']);
    }
    return [];
  }, [profileShoppings]);

  useEffect(() => {
    if (responseGruposCobranca !== undefined) {
      const listGrupoCobranca: Option[] = [];
      responseGruposCobranca.data.content.forEach((item: any) => {
        listGrupoCobranca.push({
          value: item.idGrupoCobranca,
          label: item.nome,
        });
      });

      setBillingGroupsList(listGrupoCobranca);
    }
  }, [responseGruposCobranca]);

  const last12Months = () => {
    const datesArr = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 14; i++) {
      const value = moment().subtract(i, 'month').format('MM/YYYY');
      datesArr.push({
        value,
        label: value,
      });
    }

    return datesArr;
  };

  const handleChangePagination = (event: any, value: any) => {
    setPage(value);
    setLoading(true);
    pesquisar(value);
  };

  function montarBuscaShoppings() {
    if (selectedShopping.find(s => s.value === 'todos')) {
      return (
        shoppingsOptions
          // @ts-ignore
          .filter(s => s.value !== 'todos')
          .map(e => e.value)
          .toString()
      );
    }
    return selectedShopping.map(e => e.value).toString();
  }

  const pesquisar = async (pgnb: number) => {
    setLoading(true);
    try {
      const newPage = pgnb || page;
      setPage(newPage);
      const params = {
        shoppingIds: montarBuscaShoppings(),
        idsGruposCobranca: billingGroups.length > 0 ? billingGroups.map(e => e.value).toString() : undefined,
        numeroChamado: numeroChamado.length > 0 ? numeroChamado : undefined,
        idsFases: idsFases.length > 0 ? idsFases.map(e => e.value).toString() : undefined,
        cpfCnpj: cpfCnpj.length > 0 ? cpfCnpj : undefined,
        luc: luc.length > 0 ? luc : undefined,
        mesAnoCicloAjuizamento: mesAnoCicloAjuizamento ? mesAnoCicloAjuizamento.value : undefined,
        tiposAcao: tipoAcao.length > 0 ? tipoAcao.map(e => e.value).join(',') : undefined,
        nomeFantasia,
        acoesAjuizadas,
        size: 10,
        acoesPendentes,
        page: newPage,
      };
      const response = await api.get('/workflow/ajuizamento/acompanhamento', {
        params,
      });
      setAjuizamentos(response.data);
    } catch (error) {
      handleErrors(error);
    }

    setLoading(false);
  };

  const handleAjuizamentoExport = async () => {
    const params = {
      shoppingIds: montarBuscaShoppings(),
      idsGruposCobranca: billingGroups.length > 0 ? billingGroups.map(e => e.value).toString() : undefined,
      numeroChamado: numeroChamado.length > 0 ? numeroChamado : undefined,
      idsFases: idsFases.length > 0 ? idsFases.map(e => e.value).toString() : undefined,
      cpfCnpj: cpfCnpj.length > 0 ? cpfCnpj : undefined,
      luc: luc.length > 0 ? luc : undefined,
      mesAnoCicloAjuizamento: mesAnoCicloAjuizamento ? mesAnoCicloAjuizamento.value : undefined,
      tiposAcao: tipoAcao.length > 0 ? tipoAcao.map(e => e.value).join(',') : undefined,
      nomeFantasia,
      acoesAjuizadas,
      acoesPendentes,
    };

    const response = await api.get(`/judgment/track-judgment-actions/xls`, { responseType: 'arraybuffer', params });
    downloadXls(response);
  };

  const detalhesAjuizamento = async (acao: AcompanhamentoAjuizamentoItem) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/workflow/ajuizamento/acompanhamento/${acao.id}`);
      setAjuizamentoDetalhes(data);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
    setVisibleSection('Details');
  };

  const handleChangeClient = (ajuizamento: AcompanhamentoAjuizamentoItem) => {
    detalhesAjuizamento(ajuizamento);
  };

  const handleCheck = (typeAction: string) => {
    if (typeAction === 'acoesPendentes') {
      if (acoesAjuizadas === false && acoesPendentes === true) {
        setAcoesAjuizadas(!acoesAjuizadas);
      }
      setAcoesPendentes(!acoesPendentes);
    }
    if (typeAction === 'acoesAjuizadas') {
      if (acoesAjuizadas === true && acoesPendentes === false) {
        setAcoesPendentes(!acoesPendentes);
      }
      setAcoesAjuizadas(!acoesAjuizadas);
    }
  };

  const handleExitDetalhamento = () => {
    setAjuizamentoDetalhes(undefined);
    setVisibleSection('Main');
    pesquisar(page);
  };

  return (
    <>
      <S.Container>
        <FullScreenLoading isEnabled={isLoading || isLoadingGruposCobranca} />
        {visibleSection === 'Main' && (
          <>
            <S.FiltersGrid>
              <Select
                todos
                checkedByDefault
                placeholder="Shoppings"
                options={shoppingsOptions}
                state={[selectedShopping, setselectedShopping]}
              />
              <Select placeholder="Fase atual" options={FASE_ATUAL} state={[idsFases, setIdsFases]} />

              <Input placeholder="Informe o nº" label="Número do Chamado" state={[numeroChamado, setNumeroChamado]} />
              <Input placeholder="Informe o nº" label="CPF/CNPJ" state={[cpfCnpj, setCpfCnpj]} />
              <SingleSelect
                options={last12Months()}
                state={[mesAnoCicloAjuizamento, setMesAnoCicloAjuizamento]}
                placeholder="Mês/Ano"
              />
              <Input placeholder="Informe o nº" label="LUC" state={[luc, setLuc]} />
              <Select
                options={billingGroupsList}
                state={[billingGroups, setBillingGroups]}
                placeholder="Grupo de Cobrança"
              />
              <Select placeholder="Tipo de ação" state={[tipoAcao, setTipoAcao]} options={tipoAcaoOptions} />
              <Input placeholder="" label="Nome Fantasia" state={[nomeFantasia, setNomeFantasia]} />
              <S.FiltersActions>
                <Checkbox
                  label="Ações Pendentes"
                  onClick={() => handleCheck('acoesPendentes')}
                  checked={acoesPendentes}
                />
                <Checkbox
                  label="Ações Ajuizadas"
                  onClick={() => handleCheck('acoesAjuizadas')}
                  checked={acoesAjuizadas}
                />
                <Button text="Pesquisar" onClick={() => pesquisar(1)} />
              </S.FiltersActions>
            </S.FiltersGrid>
          </>
        )}
      </S.Container>

      {ajuizamentos && ajuizamentos.data.length > 0 && (
        <S.ContentWrapper>
          {visibleSection === 'Main' && (
            <Main
              handleExport={() => handleAjuizamentoExport()}
              handleClickAcompanhar={acao => detalhesAjuizamento(acao)}
              handlePageChange={handleChangePagination}
              ajuizamentos={ajuizamentos}
              page={page}
            />
          )}

          {visibleSection === 'Details' && ajuizamentoDetalhes && (
            <Detalhamento
              onCloseDetalhamento={handleExitDetalhamento}
              ajuizamento={ajuizamentoDetalhes}
              onChangeClient={handleChangeClient}
              ajuizamentos={ajuizamentos}
            />
          )}
        </S.ContentWrapper>
      )}
    </>
  );
};
