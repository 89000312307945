import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { MoreVert, Person } from '@material-ui/icons';
import moment from 'moment';
import * as S from './styles';
import api from '../../../_core/api';
import { Boleto, Fiador, SerasaBoletoResponse, Values } from './types';
import { failure, handleErrors, warning } from '../../../_core/services/toast';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import Table from '../../../_core/_components/table';
import { Select, Option, Input, Button, Form, Modal as CustomModal } from '../../../_core/_components';
import { AuthContext } from '../../../_main/contexts/auth';
import { mascaraDateRange } from '../../../_core/masks';
import { Modal } from './modal';
import { useCache } from '../../../_core/cache';
import { requests } from '../../../requests';

type Props = {};

export const AcompanharBoletos: React.FC<Props> = () => {
  const { shoppings: profileShoppings } = useContext(AuthContext);

  const { response: responseJustificativas, isLoading: isLoadingJustificativas } = useCache({
    key: 'getJustificativas',
    fn: requests.get('getJustificativas'),
  });

  const [justificativas, setJustificativas] = useState<any[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [shoppings, setShoppings] = useState<Option[]>([]);
  const [boletos, setBoletos] = useState<Boleto[]>([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [ciclo, setCiclo] = useState('');
  const [luc, setLuc] = useState('');
  const [numBoleto, setNumBoleto] = useState('');
  const [dataVencimento, setDataVencimento] = useState('');
  const [values, setValues] = useState<Values>();
  const [fiadores, setFiadores] = useState<Fiador[]>([]);

  useEffect(() => {
    if (profileShoppings) {
      const listShopping: Option[] = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppings(listShopping);
    }
  }, [profileShoppings]);

  useEffect(() => {
    if (responseJustificativas !== undefined) {
      const listJustificativas: any[] = [];
      responseJustificativas.data.content.map((item: any) => {
        listJustificativas.push({
          value: item.idSerasaJustificatica,
          label: item.txtJustificativa,
          campoObrigatorio: item.campoObrigatorio,
        });
        return null;
      });
      listJustificativas.unshift({
        value: 999,
        label: 'Selecione',
        campoObrigatorio: '',
      });
      setJustificativas(listJustificativas);
    }
  }, [responseJustificativas]);

  const isValid = () => {
    if (numBoleto) return true;
    if (!selectedShoppings || selectedShoppings?.length === 0) {
      failure('Shopping obrigatório!');
      return false;
    }
    return true;
  };

  const getShoppingIds = () => {
    return selectedShoppings.find(s => s.value === 'todos')
      ? shoppings.filter(s => s.value !== 'todos').map(e => e.value)
      : selectedShoppings.map(e => e.value);
  };

  const searchBoletos = async () => {
    if (!isValid()) return;
    setLoading(true);
    const payload = {
      idShopping: getShoppingIds(),
      luc: luc.length > 0 ? luc : undefined,
      numBoleto: numBoleto.length > 0 ? numBoleto : undefined,
      status: status.length > 0 ? status : undefined,
      ciclo: ciclo.length > 0 ? ciclo : undefined,
    };
    try {
      const { data } = await api.post<SerasaBoletoResponse>('/workflow/serasa/serasa-workflow/buscar/boletos', payload);
      if (data.content.length === 0) {
        warning('Pesquisa executada com sucesso, porém nenhum dado foi encontrado com os parâmetros especificados!');
      }
      setBoletos(data.content);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const findIdBoleto = (idBoleto: number) => {
    const boleto = boletos.find(e => e.idBoleto === idBoleto);
    if (boleto) {
      setValues({
        id: boleto.idBoleto,
        justificativa: boleto.serasaJustificatica?.idSerasaJustificatica ?? 999,
        observacao: boleto.txtObservacao ?? '',
        nrChamado: boleto.numChamado ?? '',
        anexo: boleto.nomeAnexo ?? '',
      });
    }
  };

  const modalContentFiador = useMemo(() => {
    const arrayInput: React.ReactNode[] = [];
    fiadores.forEach(fiador => {
      arrayInput.push(<Input label="Nome" state={[fiador.nomeFiador ?? '', () => null]} disabled />);
      arrayInput.push(<Input label="CPF/CNPJ" state={[fiador.cpfcnpjFiador ?? '', () => null]} disabled />);
      arrayInput.push(<Input label="Endereço" state={[fiador?.endereco ?? '', () => null]} disabled />);
    });
    return <Form items={arrayInput} />;
  }, [fiadores]);

  const renderedData = useMemo(() => {
    if (boletos.length > 0) {
      return boletos.map(boleto => ({
        nmShopping: boleto.nmShopping,
        nomFantasia: boleto.nomFantasia,
        numCpfcnpjLoja: boleto.serasaLoja.numCpfcnpjLoja,
        luc: boleto.serasaLoja.luc,
        numBoleto: boleto.numBoleto,
        datVencimento: moment(boleto.datVencimento, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        statusSerasa: boleto.statusWorkflowSerasa.nome,
        fiadores: boleto.fiadores.length ? (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            onClick={() => setFiadores(boleto.fiadores)}
          >
            <Person />
          </IconButton>
        ) : null,
        historicoJustificativas: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            onClick={() => findIdBoleto(boleto.idBoleto)}
          >
            <MoreVert />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [boletos]);

  return (
    <>
      <FullScreenLoading isEnabled={loading || isLoadingJustificativas} />
      {values && (
        <Modal valuesState={[values, setValues]} justificativas={justificativas} onClose={() => setValues(undefined)} />
      )}
      {fiadores.length > 0 && (
        <CustomModal title="Detalhes do Fiador" onClose={() => setFiadores([])} content={modalContentFiador} />
      )}
      <S.Wrapper>
        <Form
          items={[
            <Select
              todos
              checkedByDefault
              placeholder="Shoppings"
              state={[selectedShoppings, setSelectedShoppings]}
              options={shoppings}
            />,
            <Input label="Status" state={[status, setStatus]} />,
            <Input label="Ciclo" state={[ciclo, setCiclo]} />,
            <Input label="LUC" state={[luc, setLuc]} />,
            <Input label="Nr. Boleto" state={[numBoleto, setNumBoleto]} />,
            <Input
              label="Data vencimento (intervalo)"
              state={[dataVencimento, setDataVencimento]}
              pattern={mascaraDateRange}
              placeholder="01/01/2022 - 31/12/2024"
            />,
          ]}
          submitButton={<Button onClick={searchBoletos} text="Pesquisar" />}
        />
        {renderedData.length > 0 && (
          <Table
            columns={[
              { key: 'nmShopping', orderable: true, label: 'Shopping' },
              { key: 'nomFantasia', orderable: true, label: 'Nome Fantasia' },
              { key: 'numCpfcnpjLoja', orderable: true, label: 'CPF/CNPJ' },
              { key: 'luc', orderable: true, label: 'LUC' },
              { key: 'numBoleto', orderable: true, label: 'Nº Boleto' },
              { key: 'datVencimento', orderable: true, label: 'Vencimento' },
              { key: 'statusSerasa', orderable: true, label: 'Status Workflow' },
              { label: 'Fiadores', key: 'fiadores' },
            ]}
            data={renderedData}
          />
        )}
      </S.Wrapper>
    </>
  );
};
