import styled from 'styled-components';

export const Wrapper = styled.div``;

export const FilterBar = styled.div`
  display: flex;
  gap: 16px;
  align-items: end;
  margin: 15px 0;
`;

export const FilterGroup = styled.div`
  min-width: 300px;
`;

export const FilterLabel = styled.p``;
