import React, { useState, useEffect, useMemo } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import * as S from '../styles';
import Modal from './modal';
import ConfirmationModal from '../../../../_core/_components/confirmation-modal';
import api from '../../../../_core/api';
import { failure, success } from '../../../../_core/services/toast';
import Table from '../../../../_core/_components/table';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button, Input } from '../../../../_core/_components';

export type ContractType = {
  idAjzTipoContrato: number;
  codContrato?: string;
  nome?: string;
  createdBy: string;
  modifiedBy: string;
  createdAt: Date;
  updatedAt: Date;
  flagAtivo: boolean;
};

const TiposDeContrato: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [registerList, setRegisterList] = useState<ContractType[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRegister, setSelectedRegister] = useState<ContractType | null>(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get<ContractType[]>('/setup/rules/contratos');
      setRegisterList(data.filter(d => d.flagAtivo));
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRegister(null);
  };

  const handleFinishedSaving = () => {
    setModalOpen(false);
    setSelectedRegister(null);
    getData();
  };

  const handleEditItem = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };

  const filteredItems = useMemo(() => {
    if (searchText) {
      const filtered = registerList.filter(reg => reg.nome?.toUpperCase().includes(searchText.toUpperCase()));
      return filtered;
    }
    return registerList;
  }, [searchText, registerList]);

  const excludeItem = async () => {
    try {
      await api.patch(`/setup/rules/contratos/${selectedRegister?.idAjzTipoContrato}/deactivate`);
      success('O registro foi removido');
      getData();
      setConfirmationOpen(false);
      setAnchorEl(null);
      setSelectedRegister(null);
    } catch (e) {
      failure('Houve um erro ao tentar remover!');
    }
    setSelectedRegister(null);
  };

  const renderedData = useMemo(() => {
    if (filteredItems.length > 0) {
      return filteredItems.map(item => ({
        id: item.idAjzTipoContrato,
        nome: item.nome?.toUpperCase(),
        codContrato: item.codContrato?.toUpperCase(),
        actions: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.idAjzTipoContrato.toString()}
            onClick={(e: any) => {
              setAnchorEl(e.currentTarget);
              setSelectedRegister(item);
            }}
          >
            <MoreVert />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [filteredItems]);

  const cancelConfirmation = () => {
    setConfirmationOpen(false);
    setSelectedRegister(null);
  };

  return (
    <S.DataContainer>
      <FullScreenLoading isEnabled={loading} />
      <ConfirmationModal
        open={isConfirmationOpen}
        text="Deseja excluir o registro?"
        handleCancel={() => cancelConfirmation()}
        handleOk={excludeItem}
      />
      {isModalOpen && (
        <Modal
          onFinishSave={() => handleFinishedSaving()}
          open
          onClose={() => handleCloseModal()}
          selectedRegister={selectedRegister}
        />
      )}

      <S.TopBar>
        <S.SearchBar>
          <S.SearchField>
            <Input label="Buscar" state={[searchText, setSearchText]} />
          </S.SearchField>
        </S.SearchBar>
        <Button text="Criar novo" onClick={() => setModalOpen(true)} />
      </S.TopBar>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleEditItem}>Editar</MenuItem>
        <MenuItem onClick={() => setConfirmationOpen(true)}>Excluir</MenuItem>
      </Menu>
      {renderedData.length > 0 && (
        <Table
          columns={[
            { label: 'Id', key: 'id' },
            { label: 'Nome', key: 'nome' },
            { label: 'Código', key: 'codContrato' },
            { label: '', key: 'actions' },
          ]}
          data={renderedData}
        />
      )}
    </S.DataContainer>
  );
};

export default TiposDeContrato;
