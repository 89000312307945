import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import * as S from './styles';
import BoletoTable from '../../components/table';
import ModalNegociar from './ModalNegociar';
import ModalExpectativa from './ModalExpectativa';
import { PriorizationItem } from '../../../priorizacao/types';
import api from '../../../../../_core/api';
import { Contract, ContractResponse, Slip, SlipsResponse } from '../../types';
import { failure, success } from '../../../../../_core/services/toast';
import { Button } from '../../../../../_core/_components';

type Props = {
  customerPriorizationData: PriorizationItem & {
    selectedDate: Date;
  };
  onUpdateContracts: (contractRes: ContractResponse) => void;
};

const SaldoEmAberto: React.FC<Props> = ({ customerPriorizationData, onUpdateContracts }) => {
  const [negociarOpen, setNegociarOpen] = useState(false);
  const [expectativaOpen, setExpectativaOpen] = useState(false);
  const [contracts, setContracts] = useState<Contract[]>();
  const [contractsWithSelection, setContractsWithSelection] = useState<Contract[]>();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingRelatorios, setLoadingRelatorios] = useState(false);

  useEffect(() => {
    getContracts();
  }, [customerPriorizationData]);

  const getContracts = async () => {
    try {
      setLoading(true);
      const { data } = await api.get<ContractResponse>(
        `/api/allos/v1/prioritization/detail/client/${customerPriorizationData.clientId}/month/${
          moment(customerPriorizationData.selectedDate).month() + 1
        }/year/${moment(customerPriorizationData.selectedDate).year()}/shoppingName/${
          customerPriorizationData.shoppingName
        }/unitEcon/${customerPriorizationData.unitEcon}/contracts`,
      );

      setContracts(data.prioritizationContractList);
      onUpdateContracts(data);
      setContractsWithSelection(data.prioritizationContractList);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      failure('Erro ao tentar recuperar contratos. Entre em contato com a equipe técnica');
    }
  };

  const toggleLoadingContract = (contrato: Contract, loading: boolean) => {
    setContracts(prevContracts => {
      return prevContracts?.map(c => {
        if (c.luc === contrato.luc && c.contractId === contrato.contractId) {
          return {
            ...c,
            isLoading: loading,
          };
        }
        return c;
      });
    });
  };

  const handleClickContract = async (contrato: Contract, expanded: boolean) => {
    if (expanded) {
      try {
        toggleLoadingContract(contrato, true);
        const { data } = await api.get<SlipsResponse>(
          `/api/allos/v1/prioritization/detail/contract/${contrato.contractId}/month/${
            moment(customerPriorizationData.selectedDate).month() + 1
          }/year/${moment(customerPriorizationData.selectedDate).year()}/slips?page=1&size=9999`,
        );

        setContracts(prevContracts => {
          return prevContracts?.map(c => {
            if (c.luc === contrato.luc && c.contractId === contrato.contractId) {
              return {
                ...c,
                slips: data.data.filter(b => b.luc === c.luc),
              };
            }
            return c;
          });
        });

        toggleLoadingContract(contrato, false);
      } catch (e) {
        toggleLoadingContract(contrato, false);
        failure('Erro ao tentar recuperar boletos. Entre em contato com a equipe técnica.');
      }
    }
  };

  const getSelectedBolsCount = () => {
    return contractsWithSelection?.reduce((total, contrato) => {
      if (contrato.slips) {
        return total + contrato.slips?.length;
      }
      return total;
    }, 0);
  };

  const getBoletosSelecionados = () => {
    const boletos: Slip[] = [];
    if (contractsWithSelection) {
      contractsWithSelection.forEach(c => c.slips?.forEach(s => boletos.push(s)));
    }

    return boletos;
  };

  const handleClickExpectativa = () => {
    if (getSelectedBolsCount() === 1) {
      setExpectativaOpen(true);
    } else {
      failure(`Selecione UM boleto para definir Expectativa de Recebimento. Selecionados: ${getSelectedBolsCount()}`);
    }
  };

  const handleChangeSlips = (slips: Slip[] | undefined, contrato: Contract) => {
    setContractsWithSelection(prevContracts => {
      return prevContracts?.map(c => {
        if (c.luc === contrato.luc && c.contractId === contrato.contractId) {
          return {
            ...c,
            slips,
          };
        }
        return c;
      });
    });
  };

  const handleNegociar = () => {
    if (getBoletosSelecionados() && getBoletosSelecionados().length > 0) return setNegociarOpen(true);
    return failure('Selecione ao menos um boleto');
  };

  const handleExportRelatorioDividas = async () => {
    try {
      setLoadingRelatorios(true);
      const response = await api.get<Blob>('/api/allos/v1/prioritization/report/debtReport', {
        responseType: 'blob', // Necessário para baixar arquivos binários
        params: {
          razaoSocial: customerPriorizationData.companyName,
          nroContrato: contracts?.map(e => e.contractId).join(','),
          unidEcon: customerPriorizationData.unitEcon,
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Relatorio_Dividas_${customerPriorizationData.companyName}.xlsx`); // Nome do arquivo que será baixado
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoadingRelatorios(false);
      success('Arquivo baixado. Confira seu gerenciador de downloads.');
    } catch (error) {
      failure('Erro ao tentar baixar arquivo, entre em contato com a equipe técnica');
      setLoadingRelatorios(false);
    }
  };

  const handleExportDetalhe = async () => {
    try {
      setLoadingRelatorios(true);
      const response = await api.get<Blob>(
        `/api/allos/v1/prioritization/report/client/${customerPriorizationData.clientId}/month/${
          moment(customerPriorizationData.selectedDate).month() + 1
        }/year/${moment(customerPriorizationData.selectedDate).year()}/exportReport?nroContrato=${contracts
          ?.map(e => e.contractId)
          .join(',')}&nroBoleto=${getBoletosSelecionados()
          .map(e => e.slipNumber)
          .join(',')}`,
        {
          responseType: 'blob', // Necessário para baixar arquivos binários
        },
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Detalhe_Boletos_${customerPriorizationData.companyName}.xlsx`); // Nome do arquivo que será baixado
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoadingRelatorios(false);
      success('Arquivo baixado. Confira seu gerenciador de downloads.');
    } catch (error) {
      failure('Erro ao tentar baixar arquivo, entre em contato com a equipe técnica');
      setLoadingRelatorios(false);
    }
  };

  const isNegociarEnabled = getBoletosSelecionados() && getBoletosSelecionados().length > 0;

  return (
    <S.Wrapper>
      {negociarOpen && (
        <ModalNegociar
          onRequestUpdate={() => getContracts()}
          contracts={contractsWithSelection}
          onClose={() => setNegociarOpen(false)}
        />
      )}

      {expectativaOpen && (
        <ModalExpectativa
          companyId={customerPriorizationData.companyId}
          boletos={getBoletosSelecionados()}
          onClose={() => setExpectativaOpen(false)}
          onRequestUpdate={() => getContracts()}
        />
      )}

      {isLoading ? (
        <S.LoadingWrapper>
          <CircularProgress />
        </S.LoadingWrapper>
      ) : (
        contracts?.map((contrato, i) => (
          <Accordion key={i} onChange={(_, expanded) => handleClickContract(contrato, expanded)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <S.AccordionGroup>
                <S.AccordionLabel>Contrato: </S.AccordionLabel>
                <S.AccordionValue>{contrato.contractId}</S.AccordionValue>
              </S.AccordionGroup>
              <S.AccordionGroup>
                <S.AccordionLabel>LUC: </S.AccordionLabel>
                <S.AccordionValue>{contrato.luc}</S.AccordionValue>
              </S.AccordionGroup>
              <S.AccordionGroup>
                <S.AccordionLabel>Marca: </S.AccordionLabel>
                <S.AccordionValue>{contrato.brand}</S.AccordionValue>
              </S.AccordionGroup>
              <S.AccordionGroup>
                <S.AccordionLabel>Tipo de Contrato: </S.AccordionLabel>
                <S.AccordionValue>{contrato.contractType}</S.AccordionValue>
              </S.AccordionGroup>
              <S.AccordionGroup>
                <S.AccordionLabel>Shopping: </S.AccordionLabel>
                <S.AccordionValue>{contrato.shoppingName}</S.AccordionValue>
              </S.AccordionGroup>
            </AccordionSummary>
            <AccordionDetails>
              {contrato.isLoading ? (
                <S.LoaderContainer>
                  <CircularProgress />
                </S.LoaderContainer>
              ) : (
                <BoletoTable onChangeBoletos={(slips, con) => handleChangeSlips(slips, con)} contrato={contrato} />
              )}
            </AccordionDetails>
          </Accordion>
        ))
      )}

      <S.Separator />
      {isLoadingRelatorios && <LinearProgress />}
      <S.ActionsWrapper>
        <S.Actions>
          <S.ActionLabel>Ações</S.ActionLabel>
          <S.ActionRelatorios>
            <S.ActionButtons>
              <Button
                startIcon={<GetAppIcon />}
                onClick={() => handleExportRelatorioDividas()}
                text="Relatório de Dívidas"
              />
              <Button
                disabled={getSelectedBolsCount() === 0}
                startIcon={<ListAltIcon />}
                onClick={() => handleExportDetalhe()}
                text="Detalhar Boletos"
                variant="outlined"
              />
            </S.ActionButtons>
            <S.ActionButtons>
              <Button
                disabled={getSelectedBolsCount() !== 1}
                onClick={() => handleClickExpectativa()}
                text="Expectativa de Recebimento"
              />
              <Button disabled={!isNegociarEnabled} onClick={() => handleNegociar()} text="Negociar" />
            </S.ActionButtons>
          </S.ActionRelatorios>
        </S.Actions>
      </S.ActionsWrapper>
    </S.Wrapper>
  );
};

export default SaldoEmAberto;
