import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import api from '../../../_core/api';
import { AuthContext } from '../../../_main/contexts/auth';
import { Container, Wrapper, Section } from './styles';
import { handleErrors, success, warning } from '../../../_core/services/toast';
import Table from '../../../_core/_components/table';
import { Button, Form, Select, Option, Checkbox, Modal } from '../../../_core/_components';
import ModalContent from './modal-content';

export interface IData {
  idShopping: number;
  quantidadeBoletos: number;
  quantidadeClientes: number;
  quantidadeFiador: number;
  shopping: {
    nomeShopping: string;
  };
  cicloNegativacao: {
    id: number;
  };
}

interface ITotal {
  totalBoletos: number;
  totalClientes: number;
  totalFiadores: number;
}

type ModalValues = {
  idShopping: number;
  values: any[];
};

export const BaixaSemiautomatica: React.FC = () => {
  const { id, shoppings: profileShoppings } = useContext(AuthContext);
  const [data, setData] = useState<IData[]>([]);
  const [modalValues, setModalValues] = useState<ModalValues>();
  const [selectedModalData, setSelectedModalData] = useState<number[]>([]);
  const [selectedData, setSelectedData] = useState<number[]>([]);
  const [shoppings, setShoppings] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);

  useEffect(() => {
    if (profileShoppings) {
      const listShopping: Option[] = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppings(listShopping);
    }
  }, [profileShoppings]);

  const total = useMemo((): ITotal => {
    const values = {
      totalBoletos: 0,
      totalClientes: 0,
      totalFiadores: 0,
    };
    if (data.length > 0) {
      data.forEach(item => {
        values.totalBoletos += item.quantidadeBoletos;
        values.totalClientes += item.quantidadeClientes;
        values.totalFiadores += item.quantidadeFiador;
      });
    }
    return values;
  }, [data]);

  const renderedData = useMemo(() => {
    if (data.length > 0) {
      return data.map(item => ({
        toggle: <Checkbox checked={selectedData.includes(item.idShopping)} onClick={() => clickRow(item.idShopping)} />,
        nmShopping: item.shopping.nomeShopping,
        quantidadeClientes: item.quantidadeClientes,
        quantidadeBoletos: item.quantidadeBoletos,
        quantidadeFiador: item.quantidadeFiador,
        actions: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.idShopping.toString()}
            onClick={() => getValues(item.idShopping)}
          >
            <MoreVert />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [data, selectedData]);

  const getShoppingIds = () => {
    return selectedShoppings.find(s => s.value === 'todos')
      ? shoppings.filter(s => s.value !== 'todos').map(e => e.value)
      : selectedShoppings.map(e => e.value);
  };

  const getClientes = async () => {
    setLoading(true);
    try {
      const arr: IData[] = [];
      const response = await api.post('/workflow/serasa/serasa-baixa-automatica/totalshopping', {
        shoppings: getShoppingIds(),
        codigoStatus: 'PENDENTE_APROVACAO_BAIXA_ARQUIVO_SERASA',
      });
      if (response.data.content && response.data.content.length > 0) {
        response.data.content.forEach((item: IData) => {
          arr.push(item);
        });
        setData(arr);
      } else {
        warning('Não obteve resultados.');
        setData([]);
      }
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const submit = async () => {
    setLoading(true);
    try {
      await api.post('/workflow/serasa/serasa-baixa-automatica/aprovar', {
        idUsuario: id,
        shoppings: selectedData,
      });
      success('Submetido com sucesso.');
      setSelectedData([]);
      await getClientes();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const clickRow = (idShopping: number) => {
    const arr = [...selectedData];
    const indexOf = arr.indexOf(idShopping);
    if (indexOf !== -1) {
      setSelectedData(selectedData.filter(e => e !== idShopping));
    } else {
      setSelectedData([...selectedData, idShopping]);
    }
  };

  const getValues = async (idShopping: number) => {
    setLoading(true);
    try {
      const response = await api.post('workflow/serasa/serasa-baixa-automatica/buscar/boletos-shopping', {
        idShopping: [idShopping],
        idUsuario: id,
        status: ['PENDENTE_APROVACAO_BAIXA_ARQUIVO_SERASA'],
      });
      setSelectedModalData([]);
      setModalValues({
        idShopping,
        values: response.data.content,
      });
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onClickCancel = async (numBoleto: number[]) => {
    setLoading(true);
    try {
      await api.post('/workflow/serasa/serasa-baixa-automatica/cancelar', {
        numBoleto,
        idUsuario: id,
        shoppings: [modalValues!.idShopping],
      });
      await getClientes();
      success('Boletos cancelados com sucesso.');
      setModalValues(undefined);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  return (
    <>
      <FullScreenLoading isEnabled={loading} />
      <Wrapper>
        <Container>
          <Form
            items={[
              <Select todos checkedByDefault options={shoppings} state={[selectedShoppings, setSelectedShoppings]} />,
            ]}
            submitButton={<Button onClick={getClientes} text="Pesquisar" />}
          />

          {renderedData.length > 0 && (
            <>
              <Table
                columns={[
                  { label: 'Selecionar', key: 'toggle' },
                  { label: 'Nome do Shopping', key: 'nmShopping', orderable: true },
                  { label: 'Clientes', key: 'quantidadeClientes', orderable: true },
                  { label: 'Boletos', key: 'quantidadeBoletos', orderable: true },
                  { label: 'Fiadores', key: 'quantidadeFiador', orderable: true },
                  { label: 'Detalhes', key: 'actions' },
                ]}
                data={renderedData}
                footer={['Quantidade total', '', total.totalClientes, total.totalBoletos, total.totalFiadores, '']}
              />
              <Section>
                <Button text="Enviar para baixa" onClick={submit} />
              </Section>
            </>
          )}
        </Container>
      </Wrapper>
      {modalValues && (
        <Modal
          onClose={() => setModalValues(undefined)}
          title="Detalhes"
          content={
            <ModalContent values={modalValues.values} selectedModalData={[selectedModalData, setSelectedModalData]} />
          }
          actionItems={[<Button text="Cancelar boletos" onClick={() => onClickCancel(selectedModalData)} />]}
        />
      )}
    </>
  );
};
