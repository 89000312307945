import React, { useEffect, useState } from 'react';
import * as S from './styles';
import * as T from './types';
import { Table, Option, Select, Button } from '../../../_core/_components';
import api from '../../../_core/api';
import { failure } from '../../../_core/services/toast';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';

const TableColumns = [
  { label: 'SHOPPING', key: 'nomeShopping' },
  { label: 'STATUS(WORKFLOW)', key: 'nomStatusWorkflow' },
  { label: 'DESPEJO', key: 'despejo' },
  { label: 'EXECUÇÃO', key: 'execucao' },
  { label: 'DENÚNCIA/DESPEJO', key: 'denunciaDespejo' },
  { label: 'DENÚNCIA/EXECUÇÃO', key: 'denunciaExecucao' },
];

const STATUS_OPTIONS: Option[] = [
  {
    value: 1,
    label: 'Aguardando Liberação Workflow',
  },
  {
    value: 2,
    label: 'Análise do Shopping',
  },
  {
    value: 3,
    label: 'Aprovação do Shopping',
  },
  {
    value: 4,
    label: 'Análise Corporativa',
  },
  {
    value: 5,
    label: 'Aprovação Final',
  },
];

const StatusWorkflow = () => {
  const [data, setData] = useState<T.Total[]>();
  const [shoppings, setShoppings] = useState<Option[]>();
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getList();
    getShoppings();
  }, []);

  const getShoppings = async () => {
    const { data: dataSpg } = await api.get<T.Shopping[]>('/empresas/shoppings/simple-umpaged');
    setShoppings(
      dataSpg.map<Option>(s => ({
        label: s.nome,
        value: s.id,
      })),
    );
  };

  const getShoppingIds = () => {
    if (selectedShoppings.find(s => s.value === 'todos')) {
      return shoppings?.filter(s => s.value !== 'todos').map(s => s.value);
    }

    return selectedShoppings.map(s => s.value);
  };

  const getList = async () => {
    try {
      setLoading(true);
      const res = await api.post<T.Total[]>('/workflow/ajuizamento/acoes-por-shoppping/por-status', {
        idShoppings: getShoppingIds(),
        idStatusWorkflows: selectedStatus.map(s => s.value),
      });

      setData(res.data);
      setLoading(false);
    } catch (e) {
      failure('Erro ao tentar carregar lista');
      setLoading(false);
    }
  };

  const handleSearch = () => {
    getList();
  };

  return (
    <S.Wrapper>
      <S.FilterBar>
        <S.FilterGroup>
          <S.FilterLabel>Shoppings</S.FilterLabel>
          <Select todos checkedByDefault state={[selectedShoppings, setSelectedShoppings]} options={shoppings || []} />
        </S.FilterGroup>
        <S.FilterGroup>
          <S.FilterLabel>Status</S.FilterLabel>
          <Select state={[selectedStatus, setSelectedStatus]} options={STATUS_OPTIONS || []} />
        </S.FilterGroup>

        <Button onClick={handleSearch} text="Buscar" />
      </S.FilterBar>
      {loading ? <FullScreenLoading isEnabled={loading} /> : <Table columns={TableColumns} data={data || []} />}
    </S.Wrapper>
  );
};

export default StatusWorkflow;
