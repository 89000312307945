import React, { useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import { Button, Checkbox, Form, Input, Option, SingleSelect } from '../../../../_core/_components';
import { Justificativa } from './types';
import { mascaraNumeros } from '../../../../_core/masks';

type Props = {
  data: Justificativa;
  camposObrigatorios: Option[];
  onSave: (payload: Justificativa) => void;
  onClose: () => void;
};

export const Modal: React.FC<Props> = ({ data, camposObrigatorios, onSave, onClose }: Props) => {
  const [payload, setPayload] = useState({
    ...data,
  });

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar/Editar Justificativa
        </span>
        <Grid>
          <Form
            items={[
              <Input
                placeholder="Justificativa"
                state={[
                  payload.txtJustificativa,
                  value => setPayload({ ...payload, txtJustificativa: value.toString() }),
                ]}
              />,
              <SingleSelect
                state={[
                  camposObrigatorios.find(e => e.value === payload.campoObrigatorio),
                  option => setPayload({ ...payload, campoObrigatorio: option?.value as string }),
                ]}
                options={camposObrigatorios}
              />,
              <Input
                placeholder="Limite exceção"
                state={[payload.limiteExcecao, value => setPayload({ ...payload, limiteExcecao: value.toString() })]}
                pattern={mascaraNumeros}
              />,
              <Checkbox
                checked={payload.flagAprovador}
                onClick={() => setPayload({ ...payload, flagAprovador: !payload.flagAprovador })}
                label="Flag aprovador"
              />,
              <Checkbox
                checked={payload.flagAtivo}
                onClick={() => setPayload({ ...payload, flagAtivo: !payload.flagAtivo })}
                label="Ativo"
              />,
            ]}
            submitButton={<Button onClick={() => onSave(payload)} text="Salvar" />}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
