import React, { useContext, useEffect, useMemo, useState } from 'react';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { IconButton } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { AuthContext } from '../../../_main/contexts/auth';
import api from '../../../_core/api';
import ConfirmationModal from '../../../_core/_components/confirmation-modal';
import Table from '../../../_core/_components/table';
import { handleErrors, success, warning } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { Button, Checkbox, Form, Input, Option, Select, Modal as CustomModal } from '../../../_core/_components';
import { mascaraNumerosVirgula } from '../../../_core/masks';
import { Modal } from './modal';
import * as S from './styles';

export const NegativacaoManual: React.FC = () => {
  const { id, profile, shoppings: profileShoppings } = useContext(AuthContext);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [shoppingList, setShoppingList] = useState<Option[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [numeroBoleto, setNumeroBoleto] = useState<string>('');
  const [luc, setLuc] = useState<string>('');
  const [nomeFantasia, setNomeFantasia] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [currentSelection, setCurrentSelection] = useState<any>();
  const [fiadores, setFiadores] = useState<any[]>([]);

  useEffect(() => {
    if (profileShoppings) {
      const listShopping: Option[] = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppingList(listShopping);
    }
  }, [profileShoppings]);

  const getShoppingIds = () => {
    return selectedShoppings.find(s => s.value === 'todos')
      ? shoppingList.filter(s => s.value !== 'todos').map(e => e.value)
      : selectedShoppings.map(e => e.value);
  };

  const getFilters = async () => {
    try {
      setLoading(true);
      const response = await api.post('/workflow/serasa/serasa-manual/buscar/boletos-shopping', {
        idShopping: getShoppingIds(),
        idUsuario: id,
        clientesNegativado: false,
        clientesForamNegativado: false,
        clientesNuncaNegativado: false,
        numBoletos: numeroBoleto.length > 0 ? numeroBoleto.split(',') : undefined,
        marca: nomeFantasia.length > 0 ? nomeFantasia : undefined,
        luc: luc.length > 0 ? luc : undefined,
        tipo: 'INCLUSAO_MANUAL',
      });
      if (_.isEmpty(response.data.content)) {
        warning('Não obteve resultados.');
        return;
      }
      setData(response.data.content);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const submitNegativacaoManual = async () => {
    setLoading(true);
    try {
      const boletos = data
        .filter(item => selectedItems.includes(item.idBoleto))
        .map(item => ({
          idBoleto: item.idBoleto,
          observacao: item.observation,
          loja: {
            idSerasaLoja: item.serasaLoja.idSerasaLoja,
            negativar: true,
          },
          fiadores: item.fiadores.map((e: any) => ({
            idSerasaBoletoFiador: e.idSerasaBoletoFiador,
            negativar: true,
          })),
        }));
      await api.post('/workflow/serasa/serasa-manual/negativar-manual', {
        idUsuario: id,
        boletos,
      });
      success('Negativações submetidas com sucesso');
      setSelectedItems([]);
      setData([]);
      const response = await api.post('/workflow/serasa/serasa-manual/buscar/boletos-shopping', {
        idShopping: getShoppingIds(),
        idUsuario: id,
        clientesNegativado: false,
        clientesForamNegativado: false,
        clientesNuncaNegativado: false,
        numBoletos: numeroBoleto.length > 0 ? numeroBoleto.split(',') : undefined,
        marca: nomeFantasia.length > 0 ? nomeFantasia : undefined,
        luc: luc.length > 0 ? luc : undefined,
        tipo: 'INCLUSAO_MANUAL',
      });
      if (!_.isEmpty(response.data.content)) {
        setData(response.data.content);
      }
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
    setConfirmOpen(false);
  };

  const onClickCheckbox = (idBoleto: number) => {
    const arr = [...selectedItems];
    if (arr.includes(idBoleto)) {
      setSelectedItems(arr.filter(e => e !== idBoleto));
    } else {
      arr.push(idBoleto);
      setSelectedItems(arr);
    }
  };

  const bToggleAll = useMemo(() => {
    const allBoletosIds = data.map(e => e.idBoleto);
    const selectedIds = selectedItems.map(e => e);
    return allBoletosIds.every(e => selectedIds.includes(e));
  }, [selectedItems, data]);

  const onToggleAll = () => {
    if (bToggleAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(data.map(e => e.idBoleto));
    }
  };

  const onSaveJustificativa = (obs: string) => {
    setIsOpenModal(false);
    setData(prevData =>
      prevData.map(d => {
        if (d.idBoleto === currentSelection?.idBoleto) {
          return {
            ...d,
            observation: obs,
          };
        }
        return d;
      }),
    );
  };

  const handleOpenJustificativaModal = (item: any) => {
    setIsOpenModal(true);
    setCurrentSelection(item);
  };

  const modalContentFiador = useMemo(() => {
    const arrayInput: React.ReactNode[] = [];
    fiadores.forEach(fiador => {
      arrayInput.push(<Input label="Nome" state={[fiador.nomeFiador ?? '', () => null]} disabled />);
      arrayInput.push(<Input label="CPF/CNPJ" state={[fiador.cpfcnpjFiador ?? '', () => null]} disabled />);
      arrayInput.push(<Input label="Endereço" state={[fiador?.endereco ?? '', () => null]} disabled />);
    });
    return <Form items={arrayInput} />;
  }, [fiadores]);

  const renderedData = useMemo(() => {
    if (data.length > 0) {
      const fiadoresOptions: any[][] = [];
      data.forEach((item: any) => {
        fiadoresOptions.push(
          item.fiadores.map((fiador: any) => ({
            value: fiador.cpfcnpjFiador,
            label: fiador.nomeFiador,
          })),
        );
      });
      return data.map((item, i) => ({
        toggle: (
          <Checkbox onClick={() => onClickCheckbox(item.idBoleto)} checked={selectedItems.includes(item.idBoleto)} />
        ),
        idBoleto: item.idBoleto,
        nmShopping: item.nmShopping,
        nomFantasia: item.nomFantasia,
        luc: item.luc,
        datVencimento: item.datVencimento ? moment(item.datVencimento).format('DD/MM/YYYY') : '-',
        numBoleto: item.numBoleto,
        valTotalVencido: item.valTotalVencido
          ? item.valTotalVencido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
          : '-',
        fiadores: item.fiadores.length ? (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            onClick={() => setFiadores(item.fiadores)}
          >
            <Person />
          </IconButton>
        ) : null,
        justificativa: (
          <S.CustomIconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            onClick={() => handleOpenJustificativaModal(item)}
            isActive={Boolean(item.observation)}
          >
            <RateReviewIcon />
          </S.CustomIconButton>
        ),
      }));
    }
    return [];
  }, [data, selectedItems]);

  const download = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        `/workflow/serasa/serasa-manual/download/boletos-shopping`,
        {
          idShopping: getShoppingIds(),
          idUsuario: id,
          clientesNegativado: false,
          clientesForamNegativado: false,
          clientesNuncaNegativado: false,
          numBoletos: numeroBoleto.length > 0 ? numeroBoleto.split(',') : undefined,
          marca: nomeFantasia.length > 0 ? nomeFantasia : undefined,
          luc: luc.length > 0 ? luc : undefined,
          tipo: 'INCLUSAO_MANUAL',
        },
        {
          responseType: 'arraybuffer',
        },
      );
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const handleSendNegativacao = () => {
    const found = data.find(r => selectedItems.includes(r.idBoleto) && !r.observation);

    if (found) {
      warning('Os itens selecionados precisam de uma justificativa associada.');
      return;
    }

    setConfirmOpen(true);
  };

  return (
    <>
      <FullScreenLoading isEnabled={loading} />
      <S.Container>
        <Form
          items={[
            <Select
              state={[selectedShoppings, setSelectedShoppings]}
              options={shoppingList}
              disabled={profile.includes('SHOPPING')}
              placeholder="Shoppings"
              todos
              checkedByDefault
            />,
            <Input label="Nome fantasia" state={[nomeFantasia, setNomeFantasia]} />,
            <Input label="LUC" state={[luc, setLuc]} />,
            <Input label="Número do boleto" state={[numeroBoleto, setNumeroBoleto]} pattern={mascaraNumerosVirgula} />,
          ]}
          submitButton={<Button onClick={getFilters} text="Pesquisar" />}
        />

        {renderedData.length > 0 && (
          <>
            <Table
              columns={[
                { label: <Checkbox type="secondary" checked={bToggleAll} onClick={onToggleAll} />, key: 'toggle' },
                { label: 'Nome do Shopping', key: 'nmShopping', orderable: true },
                { label: 'Nome fantasia', key: 'nomFantasia', orderable: true },
                { label: 'LUC', key: 'luc', orderable: true },
                { label: 'Vencimento do boleto', key: 'datVencimento', orderable: true },
                { label: 'Número do boleto', key: 'numBoleto', orderable: true },
                { label: 'Valor do Boleto', key: 'valTotalVencido', orderable: true },
                { label: 'Fiadores', key: 'fiadores' },
                { label: 'Justificativa', key: 'justificativa' },
              ]}
              data={renderedData}
            />
            <S.Section>
              <Button
                disabled={selectedItems.length === 0}
                onClick={() => handleSendNegativacao()}
                text="NEGATIVAR LOJISTA"
              />
              <Button text="EXPORTAR" onClick={download} />
            </S.Section>
          </>
        )}
        <ConfirmationModal
          handleCancel={() => setConfirmOpen(false)}
          handleOk={submitNegativacaoManual}
          open={confirmOpen}
          text="Por favor, confirmar que deseja enviar para a negativação junto ao Serasa os boletos selecionados em tela"
          confirmButtonText="NEGATIVAR"
        />
        {isOpenModal && (
          <Modal
            currentSelection={currentSelection}
            onSave={(obs: string) => onSaveJustificativa(obs)}
            onClose={() => setIsOpenModal(!isOpenModal)}
          />
        )}
        {fiadores.length > 0 && (
          <CustomModal title="Detalhes do Fiador" onClose={() => setFiadores([])} content={modalContentFiador} />
        )}
      </S.Container>
    </>
  );
};
