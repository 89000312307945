import styled from 'styled-components';

export const ColumnSubrow = styled.div`
  display: flex;
  flex-direction: column;

  span {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    text-decoration-skip-ink: none;
    color: #3f3f3f;
  }

  p {
    margin: 8px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-decoration-skip-ink: none;
    color: #3f3f3f;
  }
`;

export const TablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  .MuiCheckbox-root {
    padding: 0;
  }

  .MuiFormControlLabel-root {
    margin: 0;
  }

  .subItemsContainer {
    background-color: #dfeef0 !important;
  }

  .subItemsContainer > td {
    padding: 16px 0; /* Exemplo de padding */
  }

  #react-select-2-placeholder {
    font-size: 12px;
  }
`;

export const MonthIndicationHeading = styled.div`
  //styleName: Siscob/Subtitle 2;
  font-size: 12px;
  color: #15c170;
  margin: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 20px;
    font-weight: 500;
  }
`;
