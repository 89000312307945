import React, { useState, useMemo, useEffect } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import FileSaver from 'file-saver';
import * as S from './styles';
import Modal from './modal';
import api from '../../../_core/api';
import { Recomendation } from './types';
import { failure, handleErrors, success } from '../../../_core/services/toast';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { getStatusWorkflow } from './ultils';
import { Form, statusOptions } from './form';
import { Button, Table, Option } from '../../../_core/_components';
import { DetalhamentoDivida } from './detalhamento-divida';
import { getAjuizamentoJustificativas } from '../../../requests/endpoints';
import { toReal, truncateString } from '../../../_core/services/formaters';

export const WorkflowAjuizamento: React.FC = () => {
  const location = useLocation();
  const [responseJustificativas, setResponseJustificativas] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorItemId, setAnchorItemId] = useState<number | null>(null);
  const [queryString, setQueryString] = useState('');
  const [stepWorkflow] = useState(() => getStatusWorkflow(location.pathname));
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState<Recomendation[]>([]);
  const [selectedRegister, setSelectedRegister] = useState<Recomendation>();
  const [selectedDetalhesDividas, setSelectedDetalhesDividas] = useState<any>();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<Option[]>(statusOptions);

  useEffect(() => {
    const fetchJustificativas = async () => {
      try {
        const { data } = await getAjuizamentoJustificativas();
        setResponseJustificativas(data);
      } catch (error) {
        handleErrors(error);
      }
      setLoading(false);
    };
    fetchJustificativas();
  }, []);

  const searchRecomendations = async (formQueryString: string) => {
    setLoading(true);
    try {
      setQueryString(formQueryString);
      const { data } = await api.get<Recomendation[]>(`/workflow/ajuizamento/unpaged?${formQueryString}`);
      if (data.length === 0) {
        failure('Essa busca não retornou resultado.');
      }
      setValues(data);
      setSelectedIds(data.map(d => d.id));
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const handleFinishedSaving = async (params: any) => {
    setLoading(true);
    try {
      const { justificativa, observacao, file, nrChamado } = params;
      const { requerAnexo, requerChamado } = responseJustificativas.find((e: any) => e.id === justificativa);

      if (requerAnexo && requerChamado && !file && !nrChamado) {
        throw Error('Insira número do chamado OU anexo.');
      }
      if (requerAnexo && !requerChamado && !file) throw Error('Anexo é obrigatório.');
      if (requerChamado && !requerAnexo && !nrChamado) throw Error('Número do chamado é obrigatório.');

      const formData = new FormData();
      formData.append('file', file);
      formData.append('idJustificativa', justificativa);
      formData.append('numChamado', nrChamado);
      formData.append('observacao', observacao);
      formData.append('recomendacao', 'ISENTAR');
      await api.put(`/workflow/ajuizamento/recomendar/${selectedRegister!.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSelectedRegister(undefined);
      setSelectedIds([]);
      await searchRecomendations(queryString);
      success('Enviado com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const getJustificativasSubItems = (item: Recomendation) => {
    if (item.justificativa) {
      return [
        <>
          <span style={{ color: '#028C90' }}>Justificativa:</span>
          <p>{item.justificativa}</p>
        </>,
        <>
          <span style={{ color: '#028C90' }}>Nr. do chamado:</span>
          <p>{item.numChamado}</p>
        </>,
        <>
          <span style={{ color: '#028C90' }}>Observação:</span>
          <p>{item.observacao}</p>
        </>,
      ];
    }
    return null;
  };

  const renderedData = useMemo(() => {
    if (values.length > 0) {
      const recomendations = selectedStatus.map(s => s.label);

      return values
        .filter(v => recomendations.includes(v.recomendacao))
        .map(item => ({
          id: item.id,
          contratoShoppingNome: item.contratoShoppingNome,
          grupo: item.grupo,
          contratoNomeFantasia: item.contratoNomeFantasia,
          contratoLuc: item.contratoLuc,
          contratoRazaoSocial: (
            <Tooltip title={item.contratoRazaoSocial}>
              <span>{truncateString(item.contratoRazaoSocial, 10)}</span>
            </Tooltip>
          ),
          contratoCodObjeto: item.contratoCodObjeto,
          tipoDividaNome: item.tipoDividaNome,
          contratoCpfCnpj: item.contratoCpfCnpj,
          saldoAberto: item.saldoAberto,
          saldoDevedorCorrigido: item.saldoDevedorCorrigido ? toReal(item.saldoDevedorCorrigido) : '-',
          tipoAcaoNome: item.tipoAcaoNome,
          quantidadeExcecao: item.quantidadeExcecao,
          valor: item.saldoAberto?.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            currency: 'BRL',
            style: 'currency',
          }),
          subItems: getJustificativasSubItems(item),
          ajuizar:
            item.recomendacao === 'AJUIZAR' ? (
              <span style={{ backgroundColor: '#FFF6E8', color: '#E88D03', padding: 8, borderRadius: 4 }}>AJUIZAR</span>
            ) : (
              <span style={{ backgroundColor: '#ECFBE6', color: '#038931', padding: 8, borderRadius: 4 }}>ISENTAR</span>
            ),
          modal: (
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              color="primary"
              component="span"
              id={item.id.toString()}
              onClick={(e: any) => {
                setAnchorEl(e.currentTarget);
                setAnchorItemId(item.id);
              }}
            >
              <MoreIcon />
            </IconButton>
          ),
        }));
    }
    return [];
  }, [values, selectedIds, selectedStatus]);

  const submit = async () => {
    setLoading(true);
    try {
      await api.put('/workflow/ajuizamento/avancar', {
        ids: selectedIds,
      });
      setSelectedIds([]);
      await searchRecomendations(queryString);
      success('Enviado com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const limparJustificativa = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('idJustificativa', '');
      formData.append('numChamado', '');
      formData.append('observacao', '');
      formData.append('recomendacao', 'AJUIZAR');
      await api.put(`/workflow/ajuizamento/recomendar/${selectedRegister!.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSelectedRegister(undefined);
      setSelectedIds([]);
      await searchRecomendations(queryString);
      success('Salvo com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onDownloadAnexo = async (anexoId: number, nomeArquivo: string) => {
    setLoading(true);
    try {
      const response = await api.get(`/workflow/ajuizamento/anexo/${anexoId}`, {
        responseType: 'arraybuffer',
      });
      const blob = new Blob([response.data]);
      FileSaver.saveAs(blob, nomeArquivo);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onClickExportar = async () => {
    try {
      const response = await api.get(`/workflow/ajuizamento/xls?${queryString}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `doc.xlsx`); // Nome do arquivo que será baixado
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleChangeStatus = (opts: Option[]) => {
    setSelectedStatus(opts);
  };

  return (
    <S.ContentContainer>
      <FullScreenLoading isEnabled={loading} />
      <Form changeStatus={handleChangeStatus} status={stepWorkflow} submit={searchRecomendations} />
      {selectedRegister && (
        <Modal
          onSubmit={handleFinishedSaving}
          onClear={limparJustificativa}
          onClose={() => setSelectedRegister(undefined)}
          values={selectedRegister!}
          justificativas={responseJustificativas}
          onDownloadAnexo={onDownloadAnexo}
        />
      )}
      {selectedDetalhesDividas && (
        <DetalhamentoDivida onClose={() => setSelectedDetalhesDividas(undefined)} values={selectedDetalhesDividas!} />
      )}
      {renderedData.length > 0 && (
        <>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setSelectedRegister(values.find(e => e.id === anchorItemId));
                setAnchorEl(null);
                setAnchorItemId(null);
              }}
            >
              Justificativas
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSelectedDetalhesDividas(values.find(e => e.id === anchorItemId));
                setAnchorEl(null);
                setAnchorItemId(null);
              }}
            >
              Detalhes da dívida
            </MenuItem>
          </Menu>
          <Table
            columns={[
              { label: 'Nome do Shopping', key: 'contratoShoppingNome', orderable: true },
              { label: 'Razão Social', key: 'contratoRazaoSocial', orderable: true },
              { label: 'Nome Fantasia', key: 'contratoNomeFantasia', orderable: true },
              { label: 'CPF/CNPJ', key: 'contratoCpfCnpj', orderable: true },
              { label: 'LUC', key: 'contratoLuc', orderable: true },
              { label: 'Objeto do Contrato', key: 'contratoCodObjeto', orderable: true },
              { label: 'Tipo de dívida', key: 'tipoDividaNome', orderable: true },
              { label: 'Valor do saldo critério', key: 'valor', orderable: false },
              { label: 'Saldo Devedor Corrigido', key: 'saldoDevedorCorrigido', orderable: false },
              { label: 'Ação cabível', key: 'tipoAcaoNome', orderable: true },
              { label: 'Status', key: 'ajuizar', orderable: true },
              { label: 'Ações', key: 'modal' },
            ]}
            data={renderedData}
            subItensColSpan={[2, 2, 4]}
          />
          <S.Section>
            <Button text="Submeter Recomendação" onClick={submit} />
            <Button text="Exportar" variant="outlined" onClick={onClickExportar} />
          </S.Section>
        </>
      )}
    </S.ContentContainer>
  );
};
