import React from 'react';
import { Route } from 'react-router-dom';
import { AuthRoute } from './route';
import { ClientesElegiveis } from '../../modules/serasa/clientes-elegiveis';
import { AprovarNegativacao } from '../../modules/serasa/aprovar-negativacao';
import { AcompanhamentoWorkflow } from '../../modules/serasa/acompanhamento-workflow';
import { BaixaManual } from '../../modules/serasa/baixa-manual';
import { NegativacaoManual } from '../../modules/serasa/negativacao-manual';
import { ValidarAprovacoes } from '../../modules/serasa/validar-aprovacoes';
import { RetornoSerasa } from '../../modules/serasa/retorno-serasa';
import { NegativarLojista } from '../../modules/serasa/negativar-lojista';
import { BoletosNegativados } from '../../modules/serasa/boletos-negativados';
import { AcompanharBoletos } from '../../modules/serasa/acompanhar-boletos';
import { BaixaSemiautomatica } from '../../modules/serasa/baixa-semiautomatica';
import { CadastroAjuizamento } from '../../modules/ajuizamento/cadastro';
import { ContratosElegiveis } from '../../modules/ajuizamento/contratos-elegiveis';
import { AcompanharAjuizamento } from '../../modules/ajuizamento/acompanhar-ajuizamento';
import { ImportacaoAjuizamento } from '../../modules/ajuizamento/importacao-ajuizamento';
import { ImportacaoAcordos } from '../../modules/ajuizamento/importacao-acordos';
import { ImportacaoAjuizamentoManual } from '../../modules/ajuizamento/importacao-ajuizamento-manual';
import { WorkflowAjuizamento } from '../../modules/ajuizamento/workflow-ajuizamento';
import { CadastroInadimplencia } from '../../modules/inadimplencia/novo-cadastro';
import { ProcessamentoInadimplencia } from '../../modules/inadimplencia/processamento';
import { StatusDados } from '../../modules/inadimplencia/status-dados';
import { RelatorioInadimplencia } from '../../modules/inadimplencia/relatorio';
import { ResumoInadimplencia } from '../../modules/inadimplencia/novo-resumo';
import { ImportacoesInadimplencia } from '../../modules/inadimplencia/importacoes';
import { ImportarDadosOracle } from '../../modules/inadimplencia/importar-dados-oracle';
import { CalculoLucLucInadimplencia } from '../../modules/inadimplencia/calculo';
import { CadastroGrupoCobranca } from '../../modules/configuracoes/cadastro-grupo-cobranca';
import { Perfilizacao } from '../../modules/configuracoes/perfilizacao';
import { Priorizacao } from '../../modules/cobranca/priorizacao';
import { Boletos } from '../../modules/cobranca/boletos';
import { RedirectAuth } from './redirect-auth';
import { ReenvioRetornoErro } from '../../modules/serasa/reenvio-retorno-erro';
import { CadastroEmpresas } from '../../modules/configuracoes/cadastro-empresas';
import { CartasEmail } from '../../modules/cartas-email/clientes-notificados';
import { Parametros } from '../../modules/configuracoes/parametros';
import { CadastroSerasa } from '../../modules/serasa/cadastro';
import StatusWorkflow from '../../modules/ajuizamento/status-workflow';
import ConfiguracoesExcecoes from '../../modules/configuracoes/configuracoes-excecoes';

const AuthRoutes: React.FC = () => {
  return (
    <>
      <Route exact path="/" component={RedirectAuth} />
      <AuthRoute path="/home" component={() => <></>} />
      <AuthRoute path="/priorizacao" component={Priorizacao} />
      <AuthRoute path="/boletos" component={Boletos} />
      <AuthRoute path="/contratos-elegiveis" component={ContratosElegiveis} />
      <AuthRoute path="/acompanhar-ajuizamentos" component={AcompanharAjuizamento} />
      <AuthRoute path="/resumo-de-inadimplencia" component={ResumoInadimplencia} />
      <AuthRoute path="/relatorio-inadimplencia" component={RelatorioInadimplencia} />
      <AuthRoute path="/calculo-inadimplencia" component={CalculoLucLucInadimplencia} />
      <AuthRoute path="/import-inadimplencia" component={ImportacoesInadimplencia} />
      <AuthRoute path="/importar-dados-oracle-manual" component={ImportarDadosOracle} />
      <AuthRoute path="/import-ajuizamentos" component={ImportacaoAjuizamento} />
      <AuthRoute path="/cadastro-ajuizamento" component={CadastroAjuizamento} />
      <AuthRoute path="/import-autos" component={ImportacaoAcordos} />
      <AuthRoute path="/import-ajuizamentos-manual" component={ImportacaoAjuizamentoManual} />
      <AuthRoute path="/cadastro-inadimplencia" component={CadastroInadimplencia} />
      <AuthRoute path="/processamento-inadimplencia" component={ProcessamentoInadimplencia} />
      <AuthRoute path="/status-dados" component={StatusDados} />
      <AuthRoute path="/analisar-ajuizamento-shopping" component={WorkflowAjuizamento} />
      <AuthRoute path="/aprovar-ajuizamento-shopping" component={WorkflowAjuizamento} />
      <AuthRoute path="/analisar-ajuizamento-corporativa" component={WorkflowAjuizamento} />
      <AuthRoute path="/aprovar-ajuizamento-corporativa" component={WorkflowAjuizamento} />
      <AuthRoute path="/validar-ajuizamento" component={WorkflowAjuizamento} />
      <AuthRoute path="/clientes-elegiveis" component={ClientesElegiveis} />
      <AuthRoute path="/acompanhamento-workflow" component={AcompanhamentoWorkflow} />
      <AuthRoute path="/aprovar-negativacao" component={AprovarNegativacao} />
      <AuthRoute path="/analisar-negativacao" component={AprovarNegativacao} />
      <AuthRoute path="/validar-aprovacoes" component={ValidarAprovacoes} />
      <AuthRoute path="/inclusao-baixa-manual" component={BaixaManual} />
      <AuthRoute path="/inclusao-negativacao-manual" component={NegativacaoManual} />
      <AuthRoute path="/retorno-serasa" component={RetornoSerasa} />
      <AuthRoute path="/reenvio-retorno-erro" component={ReenvioRetornoErro} />
      <AuthRoute path="/boletos-negativados" component={BoletosNegativados} />
      <AuthRoute path="/negativar-lojista" component={NegativarLojista} />
      <AuthRoute path="/acompanhar-boletos" component={AcompanharBoletos} />
      <AuthRoute path="/baixa-semiautomatica" component={BaixaSemiautomatica} />
      <AuthRoute path="/acessos-usuarios" component={Perfilizacao} />
      <AuthRoute path="/cadastro-grupo-de-cobranca" component={CadastroGrupoCobranca} />
      <AuthRoute path="/cadastro-empresas" component={CadastroEmpresas} />
      <AuthRoute path="/cartas-email" component={CartasEmail} />
      <AuthRoute path="/cadastro-serasa" component={CadastroSerasa} />
      <AuthRoute path="/configuracao-priorizacao" component={Parametros} />
      <AuthRoute path="/configuracoes-excecoes" component={ConfiguracoesExcecoes} />
      <AuthRoute path="/status-workflow" component={StatusWorkflow} />
    </>
  );
};

export default AuthRoutes;
