import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import * as S from './styles';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import Table from '../../../_core/_components/table';
import api from '../../../_core/api';
import { failure, handleErrors, success } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { mascaraDDMMAAAA } from '../../../_core/masks';
import { Form, Select, Button, Input, Option, Modal } from '../../../_core/_components';
import { AuthContext } from '../../../_main/contexts/auth';

type StatusSerasaDTO = {
  content: any[];
  datetime: string;
  error: boolean;
  message: string;
};

const STATUSES = ['FINALIZADO', 'FINALIZADO_COM_ERRO'];

export const RetornoSerasa: React.FC = () => {
  const { shoppings: profileShoppings } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [shoppings, setShoppings] = useState<Option[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [status, setStatus] = useState<Option[]>([]);
  const [ciclo, setCiclo] = useState('');
  const [luc, setLuc] = useState('');
  const [nrBoleto, setNrBoleto] = useState('');
  const [dataAtualizacao, setDataAtualizacao] = useState('');
  const [result, setResult] = useState<any[]>([]);
  const [errors, setErrors] = useState('');

  useEffect(() => {
    if (profileShoppings) {
      const listShopping: Option[] = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppings(listShopping);
    }
  }, [profileShoppings]);

  useEffect(() => {
    const selectedStatus = STATUSES.map((s, i) => ({
      value: i,
      label: s,
    }));
    setStatus(selectedStatus);
  }, []);

  const getShoppingIds = () => {
    return selectedShoppings.find(s => s.value === 'todos')
      ? shoppings.filter(s => s.value !== 'todos').map(e => e.value)
      : selectedShoppings.map(e => e.value);
  };

  const getStatusSerasa = async () => {
    setLoading(true);
    setResult([]);
    try {
      const response = await api.post(`/workflow/serasa/serasa-negativacao/buscar/negativados`, {
        idShopping: getShoppingIds(),
        luc: luc.length > 0 ? luc : undefined,
        numBoleto: nrBoleto,
        ciclo,
        status: status.map(e => e.label),
        dataStatus: dataAtualizacao ? moment(dataAtualizacao, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
      });
      const { content } = response.data as StatusSerasaDTO;
      setResult(content);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const handleDate = (value: string) => {
    if (value) {
      return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
    return '';
  };

  const handleClick = (item: any) =>
    setErrors(item.retornoSerasa.erros?.map((err: any) => err.description).join(' / '));

  const renderedData = useMemo(() => {
    if (result.length > 0) {
      return result.map(item => ({
        nmShopping: item.shopping.nomeShopping,
        nomFantasia: item.nomFantasia,
        nomRazaoSocial: item.nomRazaoSocial,
        luc: item.luc,
        numBoleto: item.numBoleto,
        documentoDevedor: item.documentoDevedor,
        tipoPessoa: item.tipoPessoa,
        // id: item.serasaBoleto.cicloNegativacao.id,
        nome: item.statusWorkflowSerasa.nome,
        status: item.retornoSerasa.status,
        dataRetornoSerasa: handleDate(item.retornoSerasa.dataRetornoSerasa),
        erros: item.retornoSerasa.erros?.length ? (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            onClick={() => handleClick(item)}
          >
            <MoreIcon />
          </IconButton>
        ) : null,
      }));
    }
    return [];
  }, [result]);

  const exportFile = async () => {
    setLoadingExport(true);
    try {
      const response = await api.post(
        `/workflow/serasa/serasa-negativacao/download/boletos-negativados`,
        {
          idShopping: getShoppingIds(),
          luc: luc.length > 0 ? luc : undefined,
          numBoleto: nrBoleto,
          ciclo,
          status: status.map(e => e.label),
          dataStatus: dataAtualizacao ? moment(dataAtualizacao, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
        },
        {
          responseType: 'arraybuffer',
        },
      );
      downloadXls(response);
      success('Arquivo baixado! Confira no gerenciador de arquivos de seu navegador.');
    } catch (error) {
      failure('Houve um erro ao tentar baixar o arquivo.');
    }
    setLoadingExport(false);
  };

  return (
    <>
      <FullScreenLoading isEnabled={loading} />
      <S.Container>
        <Form
          items={[
            <Select
              todos
              checkedByDefault
              placeholder="Shoppings"
              options={shoppings}
              state={[selectedShoppings, setSelectedShoppings]}
            />,
            <Select
              placeholder="Status"
              options={STATUSES.map((e, i) => ({ value: i, label: e }))}
              state={[status, setStatus]}
            />,
            <Input label="LUC" state={[luc, setLuc]} />,
            <Input label="Número do boleto" state={[nrBoleto, setNrBoleto]} />,
            <Input label="Data Atualização" state={[dataAtualizacao, setDataAtualizacao]} pattern={mascaraDDMMAAAA} />,
            // <Input label="Ciclo" state={[ciclo, setCiclo]} />,
          ]}
          submitButton={<Button onClick={getStatusSerasa} text="Pesquisar" />}
        />

        {result.length > 0 && (
          <>
            <Table
              columns={[
                { label: 'Shopping', key: 'nmShopping', orderable: true },
                { label: 'Nome fantasia', key: 'nomFantasia', orderable: true },
                { label: 'Razão Social', key: 'nomRazaoSocial', orderable: true },
                { label: 'LUC', key: 'luc', orderable: true },
                { label: 'Número do boleto', key: 'numBoleto', orderable: true },
                { label: 'Doc. do Devedor', key: 'documentoDevedor', orderable: true },
                { label: 'Tipo Pessoa', key: 'tipoPessoa', orderable: true },
                // { label: 'Ciclo', key: 'id', orderable: true },
                { label: 'Status Workflow', key: 'nome', orderable: true },
                { label: 'Status retorno Serasa', key: 'status', orderable: true },
                { label: 'Data retorno', key: 'dataRetornoSerasa', orderable: true },
                { label: 'Mensagem de erro', key: 'erros' },
              ]}
              data={renderedData}
            />
            <S.Actions>
              <Button text="exportar" onClick={exportFile} disabled={loadingExport} />
            </S.Actions>
            {errors && (
              <Modal title="Mensagem de erro" onClose={() => setErrors('')} content={<S.Label>{errors}</S.Label>} />
            )}
          </>
        )}
      </S.Container>
    </>
  );
};
