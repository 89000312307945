import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Contract, Slip, Title } from '../types';
import api from '../../../../_core/api';
import { handleErrors } from '../../../../_core/services/toast';
import { Table, Checkbox, SingleSelect, Option } from '../../../../_core/_components';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import * as S from './styles';

const CURRENT_MONTH = moment().month() + 1;

type Props = {
  contrato: Contract;
  onChangeBoletos: (boletos: Slip[] | undefined, contrato: Contract) => void;
};

const CustomizedTables: React.FC<Props> = ({ contrato, onChangeBoletos }) => {
  const [loading, setLoading] = useState(false);
  const [boletos, setBoletos] = useState<Slip[] | undefined>([]);
  const [selectedMonth, setSelectedMonth] = useState<Option>();
  const [monthOptions, setMonthOptions] = useState<Option[]>([]);

  const currentMonthSlips = boletos?.filter(e => {
    const [, month] = e.dueDate.split('-');
    return month === CURRENT_MONTH.toString();
  });
  const currentMonthSlipsIDs = currentMonthSlips?.map(e => e.slipId);
  const pastSlips = boletos?.filter(e => !currentMonthSlipsIDs?.includes(e.slipId));

  useEffect(() => {
    setBoletos(contrato.slips);
  }, [contrato]);

  useEffect(() => {
    onChangeBoletos(
      boletos?.filter(b => b.isSelected),
      contrato,
    );
  }, [boletos]);

  const setTransactions = (bol: Slip, isOpen: boolean, transacoes?: Title[]) => {
    const newBoletos = boletos?.map(b => {
      if (b.slipNumber === bol.slipNumber) {
        return {
          ...b,
          isOpen,
          transacoes: transacoes || [],
        };
      }
      return b;
    });

    setBoletos(newBoletos);
  };

  const toggleBoleto = (bol: Slip) => {
    const newBoletos = boletos?.map(b => {
      if (b.slipId === bol.slipId) {
        return { ...b, isSelected: !b.isSelected };
      }
      return b;
    });

    setBoletos(newBoletos);
  };

  const handleOpenBoleto = async (boleto: Slip) => {
    try {
      if (!boleto.isOpen) {
        setLoading(true);
        const { data } = await api.get<Title[]>(`/api/allos/v1/prioritization/detail/slipId/${boleto.slipId}/titles`);
        setTransactions(boleto, true, data);
      } else {
        return setTransactions(boleto, false);
      }
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const renderSubItems = (item: Title[]) => {
    return [
      null,
      null,
      <S.ColumnSubrow>
        <span>Nº documento</span>
        {item.map(row => (
          <p>{row.numeroTitulo}</p>
        ))}
      </S.ColumnSubrow>,
      <S.ColumnSubrow>
        <span>Tipo documento</span>
        {item.map(row => (
          <p>{row.nomeTitulo}</p>
        ))}
      </S.ColumnSubrow>,
      <S.ColumnSubrow>
        <span>Tipo movimento</span>
        {item.map(row => (
          <p>{row.tipoMovimento}</p>
        ))}
      </S.ColumnSubrow>,

      <S.ColumnSubrow>
        <span>Competência</span>
        {item.map(row => (
          <p>{moment(row.dataCompetencia).format('DD/MM/YYYY')}</p>
        ))}
      </S.ColumnSubrow>,
      <S.ColumnSubrow>
        <span>Data vencimento</span>
        {item.map(row => (
          <p>{moment(row.dataVencimento).format('DD/MM/YYYY')}</p>
        ))}
      </S.ColumnSubrow>,
      <S.ColumnSubrow>
        <span>Data venc. original</span>
        {item.map(row => (
          <p>{row.dataContabilizacao ? moment(row.dataContabilizacao).format('DD/MM/YYYY') : '-'}</p>
        ))}
      </S.ColumnSubrow>,

      <S.ColumnSubrow>
        <span>Valor</span>
        {item.map(row => (
          <p>{row.valorVencimentoOriginal?.toLocaleString()}</p>
        ))}
      </S.ColumnSubrow>,
    ];
  };

  const renderedData = useMemo(() => {
    if (currentMonthSlips) {
      return currentMonthSlips.map(e => ({
        ...e,
        slipValueStr: e.slipValue?.toLocaleString(),
        dueDateStr: moment(e.dueDate).format('DD/MM/YYYY'),
        arrow: (
          <IconButton aria-label="expand row" size="small" onClick={() => handleOpenBoleto(e)}>
            {e.isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        ),
        checkbox: <Checkbox onClick={() => toggleBoleto(e)} checked={e.isSelected || false} />,
        subItems: e.isOpen && e.transacoes ? renderSubItems(e.transacoes!) : undefined,
      }));
    }
    return [];
  }, [currentMonthSlips]);

  const getUniqueDatesByMonth = (data: Slip[]) => {
    const uniqueDates = new Set<string>();

    data.forEach(item => {
      const formattedDate = moment(item.dueDate).format('MM/YYYY');
      uniqueDates.add(formattedDate);
    });

    return Array.from(uniqueDates).sort((a, b) => moment(b, 'MM/YYYY').valueOf() - moment(a, 'MM/YYYY').valueOf());
  };

  const filterItemsByMonth = (data: Slip[], monthYear: string) => {
    return data.filter(item => {
      const itemMonthYear = moment(item.dueDate).format('MM/YYYY');
      return itemMonthYear === monthYear;
    });
  };

  const renderedDataPastMonths = useMemo(() => {
    if (pastSlips) {
      let rend: Slip[] = pastSlips.map(e => ({
        ...e,
        slipValueStr: e.slipValue?.toLocaleString(),
        dueDateStr: moment(e.dueDate).format('DD/MM/YYYY'),
        arrow: (
          <IconButton aria-label="expand row" size="small" onClick={() => handleOpenBoleto(e)}>
            {e.isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        ),
        checkbox: <Checkbox onClick={() => toggleBoleto(e)} checked={e.isSelected || false} />,
        subItems: e.isOpen && e.transacoes ? renderSubItems(e.transacoes!) : undefined,
      }));

      // Filtra caso tenha filtro selecionado
      if (selectedMonth) {
        rend = filterItemsByMonth(rend, selectedMonth.label);
      }

      return rend;
    }
    return [];
  }, [boletos, currentMonthSlips]);

  useEffect(() => {
    if (monthOptions.length === 0 && renderedDataPastMonths) {
      const dates = getUniqueDatesByMonth(renderedDataPastMonths).map(v => ({
        value: v,
        label: v,
      }));
      setMonthOptions(dates);
    }
  }, [boletos]);

  const handleSelectAllClick = (currentMonth: boolean) => {
    const arrBoletos = [...boletos!];
    const isSelectedAll = currentMonth
      ? renderedData.every(e => e.isSelected)
      : renderedDataPastMonths.every(e => e.isSelected);
    const newValue = !isSelectedAll;

    const updatedBoletos = arrBoletos.map(item => {
      if (
        (currentMonth && currentMonthSlipsIDs?.includes(item.slipId)) ||
        (!currentMonth && !currentMonthSlipsIDs?.includes(item.slipId))
      ) {
        return { ...item, isSelected: newValue };
      }
      return item;
    });

    setBoletos(updatedBoletos);
  };

  if (!boletos || boletos?.length === 0) {
    return <p>Sem dados para este contrato</p>;
  }

  return (
    <S.TablesContainer>
      <FullScreenLoading isEnabled={loading} />
      <S.MonthIndicationHeading>
        <h3>Mês vigente</h3>
      </S.MonthIndicationHeading>
      {renderedData.length > 0 && (
        <Table
          columns={[
            { label: '', key: 'arrow' },
            {
              label: (
                <Checkbox
                  type="secondary"
                  checked={renderedData.every(e => e.isSelected)}
                  onClick={() => handleSelectAllClick(true)}
                />
              ),
              key: 'checkbox',
            },
            { label: 'Boleto', key: 'slipNumber' },
            { label: 'Valor', key: 'slipValueStr' },
            { label: 'Vencimento', key: 'dueDateStr' },
            { label: 'Cartas/Email', key: 'statusCartaEmail' },
            { label: 'Serasa', key: 'statusSerasa' },
            { label: 'Ajuizamento', key: 'statusAjuizamento' },
            { label: 'Status Negociação', key: 'typeNegotiation' },
            { label: 'Status Expectativa', key: 'statusExpectativa' },
          ]}
          data={renderedData}
        />
      )}
      <S.MonthIndicationHeading>
        <h3>Meses anteriores</h3>
        <SingleSelect
          hideLabel
          placeholder="Selecione um mês"
          options={monthOptions}
          state={[selectedMonth, setSelectedMonth]}
        />
      </S.MonthIndicationHeading>

      {renderedDataPastMonths.length > 0 && (
        <Table
          columns={[
            { label: '', key: 'arrow' },
            {
              label: (
                <Checkbox
                  type="secondary"
                  checked={renderedDataPastMonths.every(e => e.isSelected)}
                  onClick={() => handleSelectAllClick(false)}
                />
              ),
              key: 'checkbox',
            },
            { label: 'Boleto', key: 'slipNumber' },
            { label: 'Valor', key: 'slipValueStr' },
            { label: 'Vencimento', key: 'dueDateStr' },
            { label: 'Cartas/Email', key: 'statusCartaEmail' },
            { label: 'Serasa', key: 'statusSerasa' },
            { label: 'Ajuizamento', key: 'statusAjuizamento' },
            { label: 'Status Negociação', key: 'typeNegotiation' },
            { label: 'Status Expectativa', key: 'statusExpectativa' },
          ]}
          data={renderedDataPastMonths}
        />
      )}
    </S.TablesContainer>
  );
};

export default CustomizedTables;
