import React, { useEffect, useRef } from 'react';
import ReactSelect, { CSSObjectWithLabel, SingleValue } from 'react-select';
import { Option } from './types';

interface Props {
  state: [Option | undefined, (option: Option | undefined) => void];
  placeholder?: string;
  options: Option[];
  disabled?: boolean;
  isClearable?: boolean;
  controlStyle?: CSSObjectWithLabel;
  containerStyles?: CSSObjectWithLabel;
  callbackSelected?: (option: Option) => void;
  hideLabel?: boolean;
}

export function SingleSelect({
  state: [value, setValue],
  placeholder,
  options,
  disabled = false,
  isClearable = true,
  controlStyle = {},
  containerStyles = {},
  callbackSelected,
  hideLabel = false,
}: Props): JSX.Element {
  const ref = useRef<any>(null);

  useEffect(() => {
    if (value === undefined && ref.current?.clearValue) {
      ref.current.clearValue();
    }
  }, [value]);

  const onChange = (option: SingleValue<Option>) => {
    setValue(option || undefined);
    if (callbackSelected && option) {
      callbackSelected(option);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      {placeholder && !hideLabel && (
        <label style={{ margin: 0, color: '#6D6D6D', fontSize: 14, fontWeight: 600 }}>{placeholder}</label>
      )}
      <ReactSelect
        value={value}
        onChange={onChange}
        hideSelectedOptions={false}
        isMulti={false}
        isSearchable
        isClearable={isClearable}
        options={options}
        placeholder={hideLabel ? placeholder : ''}
        menuPortalTarget={document.body}
        styles={{
          container: base => ({
            ...base,
            ...containerStyles,
          }),
          control: (base, state) => ({
            ...base,
            height: 40,
            minHeight: 40,
            minWidth: '10rem',
            boxShadow: state.isFocused ? '0 0 0 1px #008C90' : 'none',
            borderColor: state.isFocused ? '#008C90' : 'hsl(0, 0%, 80%)',
            '&:hover': {
              borderColor: state.isFocused ? '#008C90' : 'hsl(0, 0%, 80%)',
            },
            ...controlStyle,
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          valueContainer: base => ({
            ...base,
            flexWrap: 'nowrap',
          }),
        }}
        noOptionsMessage={() => ''}
        isDisabled={disabled}
        ref={ref}
      />
    </div>
  );
}
