/* eslint-disable prefer-const */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import ReactSelect, { ActionMeta, ValueContainerProps, components } from 'react-select';
import _, { isArray } from 'lodash';
import { Option } from './types';
import { Checkbox } from './checkbox';

interface Props {
  state: [Option[], React.Dispatch<React.SetStateAction<Option[]>>];
  placeholder?: string;
  options: Option[];
  disabled?: boolean;
  callbackSelected?: (option: Option[]) => void;
  handleChange?: (options: Option[]) => void;
  todos?: boolean;
  checkedByDefault?: boolean;
}

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #008c902b;
  }

  .MuiButtonBase-root {
    padding: 0 2px !important;
    margin-left: 15px;
  }
`;

const CustomOption = (props: any) => {
  const { data, isSelected, innerRef, innerProps } = props;

  return (
    <OptionContainer ref={innerRef} {...innerProps}>
      <Checkbox stopPropagation={false} onClick={() => null} checked={isSelected} />
      <label>{data.label}</label>
    </OptionContainer>
  );
};

export function Select({
  state: [value, setValue],
  placeholder,
  options,
  disabled = false,
  callbackSelected,
  handleChange,
  todos = false,
  checkedByDefault = false,
}: Props): JSX.Element {
  const refSelect = useRef<any>(null);

  const ValueContainer = ({ children, ...props }: ValueContainerProps<any>) => {
    let [values, input] = children as any;

    if (refSelect?.current && props.selectProps.menuIsOpen) {
      refSelect.current.focus();
    }

    if (values && Array.isArray(values) && values.find((v: any) => v.key === 'TODOS-todos')) {
      return <components.ValueContainer {...props}>TODOS {input}</components.ValueContainer>;
    }

    if (Array.isArray(values)) {
      const plural = values.length === 1 ? '' : 's';
      values = `${values.length} item${plural} selecionado${plural}`;
    }

    return (
      <components.ValueContainer {...props}>
        {values}
        {input}
      </components.ValueContainer>
    );
  };

  useEffect(() => {
    if (checkedByDefault) {
      setValue([...options, { label: 'TODOS', value: 'todos' }]);
    }
  }, [options]);

  const findDifferences = (currentOptions: Option[], previousOptions: Option[]) => {
    const differences = {
      onlyInCurrent: currentOptions.filter(
        current =>
          !previousOptions.some(previous => previous.value === current.value && previous.label === current.label),
      ),
      onlyInPrevious: previousOptions.filter(
        previous =>
          !currentOptions.some(current => current.value === previous.value && current.label === previous.label),
      ),
    };

    return differences;
  };

  const onChange = (option: readonly Option[], actionMeta: ActionMeta<Option>) => {
    const previousOptions = value || [];
    const currentOptions = option || [];

    const diferences = findDifferences(currentOptions as Option[], previousOptions);

    if (diferences.onlyInPrevious.find(o => o.label === 'TODOS')) {
      setValue([]);
      return;
    }
    const addedOption = currentOptions.find(o => !previousOptions.some(prevOption => prevOption.value === o.value));

    if (addedOption?.value === 'todos') {
      setValue([...options, addedOption]);
      return;
    }

    setValue(option.filter(o => o.value !== 'todos').map(e => e));
    if (callbackSelected && actionMeta.action !== 'clear') {
      const diffArr = _.difference(value, option);
      callbackSelected(diffArr);
    }

    if (handleChange) {
      handleChange(option as Option[]);
    }
  };

  const renderOptions = [...options];

  if (todos && !options.find(o => o.value === todos)) {
    renderOptions.unshift({
      label: 'TODOS',
      value: 'todos',
    });
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      {placeholder && (
        <label style={{ margin: 0, color: '#6D6D6D', fontSize: 14, fontWeight: 600 }}>{placeholder}</label>
      )}
      <ReactSelect
        ref={refSelect}
        value={value}
        onChange={onChange}
        hideSelectedOptions={false}
        placeholder=""
        isMulti
        isSearchable
        isClearable
        options={renderOptions}
        menuPortalTarget={document.body}
        closeMenuOnSelect={false}
        classNamePrefix="react-select"
        styles={{
          control: (base, state) => ({
            ...base,
            height: 40,
            minHeight: 40,
            minWidth: '10rem',
            boxShadow: state.isFocused ? '0 0 0 1px #008C90' : 'none',
            borderColor: state.isFocused ? '#008C90' : 'hsl(0, 0%, 80%)',
            '&:hover': {
              borderColor: state.isFocused ? '#008C90' : 'hsl(0, 0%, 80%)',
            },
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          valueContainer: base => ({
            ...base,
            flexWrap: 'nowrap',
            padding: '0 12px',
          }),
          multiValueRemove: base => ({ ...base, display: 'none' }),
          multiValue: base => ({ ...base, paddingRight: 3 }),
          placeholder: base => ({ ...base, marginLeft: '8px', marginRight: '8px' }),
          singleValue: base => ({ ...base, marginLeft: '8px', marginRight: '8px' }),
        }}
        noOptionsMessage={() => ''}
        components={{ ValueContainer, Option: CustomOption }}
        isDisabled={disabled}
      />
    </div>
  );
}
