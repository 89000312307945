import React from 'react';
import { Checkbox as MaterialCheckbox, FormControlLabel } from '@material-ui/core';

type Props = {
  label?: string;
  checked: boolean;
  disabled?: boolean;
  type?: 'primary' | 'secondary';
  onClick: () => void;
  stopPropagation?: boolean;
};

export function Checkbox({
  label,
  checked,
  disabled = false,
  type = 'primary',
  onClick,
  stopPropagation = true,
}: Props): JSX.Element {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    onClick();
  };

  return (
    <FormControlLabel
      control={<MaterialCheckbox disabled={disabled} checked={checked} onClick={e => handleClick(e)} color={type} />}
      label={label}
    />
  );
}
